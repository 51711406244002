<template>
  <div>
    <!-- <navBar
      title="购物车"
      left="0"
      right="1"
      rightText="删除"
      @handleFather="handleFather"
    ></navBar> -->
    <div class="shopCarNav">
      <van-checkbox v-model="checked" @click="checkAll">全选</van-checkbox>
      <div @click="handleFather">删除</div>
    </div>

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getQueryShopCartList"
    >
      <van-checkbox-group v-model="result" ref="checkboxGroup">
        <van-swipe-cell
          v-for="(item, i) in goodsList"
          :key="i"
          :name="item.ShopCartId"
          :before-close="beforeClose"
        >
          <van-checkbox
            :name="i"
            class="order_info"
            @click="changeCheckbox(item)"
          >
            <div class="info_detail" @click="goDetail(item.GoodsId)">
              <img :src="item.CoverImg" alt="" />
              <div class="detail_content">
                <div>
                  {{
                    item.GoodsName.length > 25
                      ? item.GoodsName.substr(0, 25) + "..."
                      : item.GoodsName
                  }}
                </div>
                <div>
                  <span>{{
                    item.SpecName.length > 15
                      ? item.SpecName.substr(0, 15) + "..."
                      : item.SpecName
                  }}</span>
                </div>
                <div>
                  <span v-if="item.Points > 0" style="font-size: 12px"
                    >{{ item.Points }}积分 +</span
                  >
                  ￥<span>{{ item.Price }}</span>
                </div>
              </div>
            </div>
            <!-- 数量 -->
            <div class="info_num" @click.stop="onChange(item)">
              <van-stepper v-model="item.GoodsCount" />
            </div>
          </van-checkbox>
          <template #right>
            <van-button
              square
              type="danger"
              class="delete-button"
              text="删除"
            />
          </template>
        </van-swipe-cell>
      </van-checkbox-group>
    </van-list>
    <div style="height: 50px"></div>
    <van-submit-bar
      :price="total"
      button-text="结算"
      :suffix-label="totalPoint > 0 ? '+' + totalPoint + '积分' : ''"
      @submit="onSubmit"
    >
    </van-submit-bar>
    <!-- <Nocontent v-if="goodsList.length==0" name='购物车空空如也'></Nocontent> -->
    <Nocontent v-if="goodsList.length == 0" name="购物车空空如也"></Nocontent>
  </div>
</template>

<script>
import { queryShopCartList, shopCartEdit, shopCartDelete } from "@/api/shop";
import { queryStudentInfoById } from "@/api/bbs";
import navBar from "./components/navBar.vue";
import Nocontent from "./components/nocontent.vue";
import { Toast, Dialog } from "vant";
export default {
  data() {
    return {
      checked2: false,
      result: [],
      checked: false,
      goodsList: [],
      isLoad: false,
      isEnd: false,
      pageIndex: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      total: 0,
      totalPoint: 0,
      userInfo: {},
    };
  },
  created() {
    if (
      this.$route.query.UserId != undefined &&
      this.$route.query.UserId != ""
    ) {
      this.userInfo.Id = this.$route.query.UserId;
      this.getQueryUserInfo();
    }
    var that = this;
    window["callByAndroidParam"] = function (jsonStr) {
      if (jsonStr != "") {
        that.userInfo.Id = jsonStr.data;
        that.getQueryUserInfo();
        that.getQueryShopCartList();
      } else {
        this.$message.error("登录失败");
      }
    };
  },
  mounted() {
    if (localStorage.getItem("userInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      // this.getQueryShopCartList();
    } else {
      this.userInfo.Id = 0;
      this.callApp();
    }
    window.userIDResultByIos = this.userIDResultByIos;
    // window.addEventListener('scroll', this.listenScroll);
  },
  components: {
    navBar,
    Nocontent,
  },
  methods: {
    userIDResultByIos(jsonStr) {
      if (jsonStr != "") {
        this.user.Id = jsonStr.data;
        this.getQueryUserInfo();
      } else {
        this.$message.error("登录失败");
      }
    },
    callApp() {
      let u = navigator.userAgent;
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (window.android != null && typeof window.android != "undefined") {
        //可以传递自定义数据，比如json
        window.android.callAndroid(JSON.stringify({ action: "getUserID" }));
      } else if (isiOS) {
        window.webkit.messageHandlers.callIosGetUserID.postMessage(
          JSON.stringify({ action: "callIosGetUserID" })
        );
      }
    },
    // 用户详情
    async getQueryUserInfo() {
      const res = await queryStudentInfoById(this.userInfo.Id);
      this.userInfo = res.response;
      localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
    },
    checkAll() {
      this.$refs.checkboxGroup.toggleAll(this.checked);
      this.total = 0;
      this.totalPoint = 0;
      for (var i = 0; i < this.result.length; i++) {
        let obj = this.goodsList[this.result[i]];
        this.total +=
          (obj.Price == null ? 0 : obj.Price) * obj.GoodsCount * 100;
        this.totalPoint +=
          (obj.Points == null ? 0 : obj.Points) * obj.GoodsCount;
      }
    },
    beforeClose({ name, position, instance }) {
      switch (position) {
        case "left":
        case "cell":
        case "outside":
          instance.close();
          break;
        case "right":
          Dialog.confirm({
            message: "确定删除吗？",
          })
            .then(() => {
              // console.log(name, '')
              let shopCartIds = [{ Id: name }];
              this.postShopCartDelete(shopCartIds);
              this.checked = false;
              instance.close();
            })
            .catch(() => {
              instance.close();
              // on cancel
            });
          break;
      }
    },
    // 列表数据
    async getQueryShopCartList() {
      let parm = {};
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.StudentId = this.userInfo.Id;
      const res = await queryShopCartList(parm);
      // console.log(res.response.data);
      this.goodsList = this.goodsList.concat(res.response.data);
      // console.log(this.goodsList,77777)
      this.pageIndex++;
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.goodsList.length >= res.response.dataCount) {
        this.finished = true;
      }
      // this.isLoad = false;
      // if (res.response.data.length == 0) {
      //   this.isEnd = true;
      // }
    },

    // 修改购物车数量
    async postEditShopCart(item) {
      let params = {};
      params.Id = item.ShopCartId;
      params.GoodsCount = item.GoodsCount;
      const res = await shopCartEdit(params);
      if (res.success == true) {
        Toast.success({
          message: res.msg,
        });
        // item.IsReceive = 1;
      } else {
        Toast.fail({
          message: res.msg,
        });
      }
    },
    //滚动
    listenScroll() {
      let that = this;
      let ele = document.documentElement;
      let scr = ele.scrollTop; // 向上滚动的那一部分高度
      let clientHeight = ele.clientHeight; // 屏幕高度也就是当前设备静态下你所看到的视觉高度
      let scrHeight = ele.scrollHeight; // 整个网页的实际高度，兼容Pc端
      if (scr + clientHeight + 5 >= scrHeight) {
        // console.log(that.isEnd);
        // if (that.isLoad == false||this.isEnd == false) {
        if (that.isEnd == false) {
          // that.isLoad = true;
          that.pageIndex++;
          that.getQueryShopCartList();
        }
      }
    },

    // 加减购物车

    onChange(obj) {
      console.log(obj);
      if (obj.GoodsCount > 0) {
        this.postEditShopCart(obj);
      }
      this.total = 0;
      this.totalPoint = 0;
      for (var i = 0; i < this.result.length; i++) {
        let obj = this.goodsList[this.result[i]];
        this.total +=
          (obj.Price == null ? 0 : obj.Price) * obj.GoodsCount * 100;
        this.totalPoint +=
          (obj.Points == null ? 0 : obj.Points) * obj.GoodsCount;
      }
    },

    changeCheckbox() {
      if (this.result.length == this.goodsList.length) {
        this.checked = true;
      } else {
        this.checked = false;
      }
      this.total = 0;
      this.totalPoint = 0;
      for (var i = 0; i < this.result.length; i++) {
        let obj = this.goodsList[this.result[i]];
        this.total +=
          (obj.Price == null ? 0 : obj.Price) * obj.GoodsCount * 100;
        this.totalPoint +=
          (obj.Points == null ? 0 : obj.Points) * obj.GoodsCount;
      }
    },
    // 删除购物车商品
    async postShopCartDelete(parm) {
      const res = await shopCartDelete(parm);
      if (res.success == true) {
        Toast.success({
          message: res.msg,
        });
        this.pageIndex = 1;
        this.goodsList = [];
        this.result = [];
        this.total = 0;
        this.totalPoint = 0;
        this.getQueryShopCartList();
      } else {
        Toast.fail({
          message: res.msg,
        });
      }
    },
    // 删除
    handleFather() {
      if (this.result.length == 0) {
        Toast.fail({
          message: "请选择要删除的商品",
        });
        return false;
      }
      Dialog.confirm({
        title: "删除商品",
        message: "确定删除购物车中选中的商品吗",
      })
        .then(() => {
          let shopCartIds = [];
          for (var i = 0; i < this.result.length; i++) {
            let obj = this.goodsList[this.result[i]];
            var data = {};
            data.Id = obj.ShopCartId;
            shopCartIds.push(data);
          }
          this.postShopCartDelete(shopCartIds);
        })
        .catch(() => {
          // on cancel
        });
    },
    //前去结算
    onSubmit() {
      let goodsData = [];
      for (var i = 0; i < this.result.length; i++) {
        let obj = this.goodsList[this.result[i]];
        // this.total += obj.Price * obj.GoodsCount * 100;
        let data = {};
        data.GoodsId = obj.GoodsId;
        data.SpecId = obj.SpecId;
        data.Count = obj.GoodsCount;
        data.ShopCartId = obj.ShopCartId;
        goodsData.push(data);
      }
      if (goodsData.length == 0) {
        Toast.fail({
          message: "请选择要结算的商品",
        });
        return false;
      }
      this.$router.push({
        path: "/shop/orders/confirmOrder",
        query: { goodsData: JSON.stringify(goodsData) },
      });
    },
    goDetail(id) {
      console.log(id);
      this.$router.push({
        path: "/shop/goods/detail/?id=" + id,
        // query: { goodsData:JSON.stringify(goodsData)}
      });
    },
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.listenScroll);
  },
};
</script>

<style lang="scss" scoped>
.van-submit-bar {
  z-index: 1;
  bottom: 50px;
}
/deep/ .van-submit-bar__suffix-label {
  font-size: 12px;
  color: #000;
}

.order_info {
  background: #fff;
  width: 100%;
  // height: 12.6rem;
  box-sizing: border-box;
  padding: 0.9375rem;
  margin-bottom: 0.5rem;
  position: relative;
  &:nth-child(1) {
    margin-top: 0.5rem;
  }
  .info_detail {
    display: flex;
    margin-bottom: 0.9375rem;
    img {
      width: 5.5625rem;
      height: 5.5rem;
      border-radius: 0.25rem;
      margin-right: 0.9375rem;
    }
    .detail_content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      div:first-child {
        font-size: 0.875rem;
        color: #333333;
        font-weight: 700;
        margin-right: 1.25rem;
      }
      div:nth-child(2) {
        font-size: 0.75rem;
        color: #858585;
        display: flex;
        justify-content: space-between;
      }
      div:nth-child(3) {
        font-size: 0.75rem;
        color: #fe4a4a;
        span {
          color: #fe4a4a;
          font-size: 0.9375rem;
          font-weight: 700;
        }
      }
    }
  }
  .info_freight {
    display: flex;
    justify-content: flex-end;
    font-size: 0.875rem;
    color: #333333;
    margin-bottom: 1.125rem;
    div:first-child {
      margin-right: 6.18rem;
      span:first-child {
        margin-right: 0.625rem;
      }
      span:nth-child(2) {
        color: #d2d1d1;
      }
    }
    div:nth-child(2) {
      span:first-child {
        font-size: 0.5625rem;
      }
      span:nth-child(2) {
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }
  .info_refund {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0.75rem;
    div {
      width: 4.125rem;
      height: 1.75rem;
      border-radius: 1.875rem;
      border: 1px solid #e8e8e8;
      font-size: 0.75rem;
      color: #999;
      text-align: center;
      line-height: 1.75rem;
    }
  }
  .info_price {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1rem;
    color: #333333;
    div:first-child {
      font-weight: 700;
    }
    div:nth-child(2) {
      color: #0c0c0c;
      span:first-child {
        font-size: 0.5625rem;
      }
      span:nth-child(2) {
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }
  .info_num {
    position: absolute;
    right: 0.9375rem;
    top: 4.8125rem;
  }
}
.shopCarNav {
  padding: 0 15px;
  border-bottom: 1px solid #f5f5f5;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-button {
  height: 100%;
}
/deep/ .van-tabbar-item {
  color: #000;
}
.van-button {
  background: #000;
}
</style>
